:root.light-theme {
	--link-color: #25cbec;
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #737378;
	--quaternary-color: #e4e4e7;
	--background-color: white;
	--splash-color: #efefef;
	--splash-border: #f4f4f5;
	--box-shadow-color: rgba(0, 0, 0, 0.2);
}

:root.dark-theme {
	--link-color: #25cbec;
	--primary-color: #f5f5f5;
	--secondary-color: #b0b0b4;
	--tertiary-color: #bfbfc7;
	--quaternary-color: #1e1e22;
	--background-color: #121212;
	--splash-color: #1f1f1f;
	--splash-border: black;
	--box-shadow-color: rgba(255, 255, 255, 0.2);
}

html {
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
	margin: 0;
}