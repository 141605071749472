@import "../../../data/styles.css";

.work-header {
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 350px;
}

.works-body {
	margin-bottom: 0.625rem;
	width: 100%;
}

.work {
	display: flex;
	flex-direction: row;
	align-items: center;
	mix-blend-mode: normal;
	border-radius: 10px;
	box-sizing: border-box;
	/* width: 100%;
	height: auto; */
}

.work:hover {
	background: var(--splash-color);
	opacity: 1;
	transition: background-color 0.3s ease-in-out;
}

.work-image {
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.work-title {
	font-size: 15px;
	font-weight: bold;
	width: max-content;
	margin-left: 1.25rem;
	color: var(--secondary-color);
}

.work-details {
	display: flex;
	/* justify-content: space-between; */
	font-size: 12px;
	color: var(--secondary-color);
	width: 100%;
}

.work-subtitle {
	flex: 1;
}

.work-duration {
	flex: 1;
}

/* @media (max-width: 768px) {
	.work-duration .work-subtitle {
		text-wrap: wrap;
	}
} */